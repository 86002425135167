import { stringReplacement, type Replacements, isStringEmpty } from '@mechhive/helpers';
import { Link } from 'react-router';
import type { RenderOptions } from 'storyblok-rich-text-react-renderer';
import { render } from 'storyblok-rich-text-react-renderer';
import { match } from 'ts-pattern';
import type { StoryblokDoc } from '~/api/types/storyblok/blocks/StoryblokDoc';

type StoryblokRichTextProps = {
  children: StoryblokDoc;
  stringReplacements?: Replacements;
  useRemixLink?: boolean;
  nodeResolvers?: RenderOptions['nodeResolvers'];
}

export const StoryblokRichText = ( { children, stringReplacements, useRemixLink = true, nodeResolvers }: StoryblokRichTextProps ) => {
  return (
    render( children, {
      nodeResolvers: {
        heading( children, { level } ) {
          const className = 'first:pt-0 pt-4 text-xl font-semibold mb-6 last:mb-0';

          return match( level )
            .with( 1, () => <h1 className={ className }>{ children }</h1> )
            .with( 2, () => <h2 className={ className }>{ children }</h2> )
            .with( 3, () => <h3 className={ className }>{ children }</h3> )
            .with( 4, () => <h4 className={ className }>{ children }</h4> )
            .with( 5, () => <h5 className={ className }>{ children }</h5> )
            .with( 6, () => <h6 className={ className }>{ children }</h6> )
            .otherwise( () => <p>{ children }</p> );
        },
        paragraph: nodeResolvers?.paragraph ? nodeResolvers.paragraph : ( children ) => {
          return (
            <p className={ 'leading-7 font-light pt-2 mb-4 first:pt-0 last:mb-0 text-pretty' }>{ children }</p>
          )
        },
        bullet_list( children ) {
          return (
            <ul className={ 'list-disc ml-6 mb-4 last:mb-0' }>
              { children }
            </ul>
          )
        },
        ordered_list( children ) {
          return (
            <ol className={ 'list-decimal ml-8 mb-4 last:mb-0 [&>li]:mb-4 last:[&>li]:mb-0' }>
              { children }
            </ol>
          )
        },
        list_item( children ) {
          return (
            <li className={ '[&>p]:pt-0 [&>p]:mb-0' }>
              { children }
            </li>
          )
        },
      },
      textResolver( str ) {
        if ( stringReplacements ) {
          return stringReplacement( str, stringReplacements );
        }

        return str;
      },
      markResolvers: {
        bold ( text ) {
          return (
            <strong className={ 'font-bold' }>{ text }</strong>
          )
        },
        link( text, props ) {
          let href = props.href;
          let internal : string = '';
          try {
            const url = new URL( props.href ?? '' );
            if ( url.protocol === 'http:' ) {
              url.protocol = 'https:'
            }
                        
            if ( url.hostname === 'rewarble.com' ) {
              internal = url.pathname;
            }

            href = url.toString();
          } catch ( ex ) { }

          if ( !isStringEmpty( internal ) && useRemixLink === true ) {
            return ( 
              <Link
                to={ internal }
                className={ 'text-[#8584FE] underline underline-offset-2 hover:text-[#C7C6FE] transition-colors duration-200' }> 
                { text }
              </Link>
            )
          }

          return (
            <a
              href={ href }
              target={ '_blank' }
              className={ 'underline underline-offset-2 hover:text-[#C7C6FE] transition-colors duration-200' }
              style={ {
                color: '#8584FE'
              } }
              rel="noreferrer">
              { text }
            </a>
          )
        }
      }
    } )
  )
}